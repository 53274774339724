import React from "react";
import { Col } from "antd";

import * as classes from "./Poweredby.module.scss";
import { brands } from "../Utils/data";

const Poweredby = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>Trusted, backed, and powered by</h1>
        <Col className={classes.brands}>
          {brands.map((brand) => (
            <img
              key={brand.id}
              src={brand.image}
              alt="Sales certification job program in Hyderabad"
            />
          ))}
        </Col>
      </Col>
    </Col>
  );
};

export default Poweredby;
