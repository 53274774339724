import { Col } from "antd";
import React, { useRef } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import * as classes from "./CurriculumDetails.module.scss";
import { useState } from "react";

const ModuleCard = (props) => {
  const { module } = props;

  const [active, setActive] = useState(false);
  const ref = useRef();

  const handleClose = () => {
    setActive(false);
  };

  const handleOpen = () => {
    setActive(true);
  };

  return (
    <Col className={classes.curicullumItem} key={module.id}>
      <h3>{module.title}</h3>
      <h3>{module.stats}</h3>
      <Col
        ref={ref}
        style={{
          height: active ? ref.current.scrollHeight : "0",
        }}
      >
        <ul>
          {module.content.map((chapter) => {
            return <li key={chapter.id}>{chapter.title}</li>;
          })}
        </ul>
      </Col>
      <Col className={classes.toggleIcon}>
        {active ? (
          <MinusOutlined
            className={classes.minusToggleIcon}
            onClick={handleClose}
          />
        ) : (
          <PlusOutlined
            className={classes.plusToggleIcon}
            onClick={handleOpen}
          />
        )}
      </Col>
    </Col>
  );
};

export default ModuleCard;
