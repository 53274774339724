// extracted by mini-css-extract-plugin
export var accordion = "CustomAccordion-module--accordion--77450";
export var bebasUnderlineHeader = "CustomAccordion-module--bebas-underline-header--879cd";
export var bebasUnderlineHeaderContainer = "CustomAccordion-module--bebas-underline-header-container--4cde3";
export var bebasUnderlineHeaderUnderline = "CustomAccordion-module--bebas-underline-header-underline--49a5c";
export var courseCard = "CustomAccordion-module--course-card--5433b";
export var coursesListContainer = "CustomAccordion-module--courses-list-container--0295c";
export var coursesScrollButton = "CustomAccordion-module--courses-scroll-button--0a447";
export var coursesScrollContainer = "CustomAccordion-module--courses-scroll-container--57388";
export var header = "CustomAccordion-module--header--14095";
export var headingContainer = "CustomAccordion-module--headingContainer--7bb04";
export var icon = "CustomAccordion-module--icon--4da91";
export var lmsScrollbar = "CustomAccordion-module--lms-scrollbar--8e680";
export var minusIcon = "CustomAccordion-module--minusIcon--030a8";
export var plusIcon = "CustomAccordion-module--plusIcon--b6dbf";
export var sectionPillHeaderDesktop = "CustomAccordion-module--section-pill-header-desktop--03a70";
export var text = "CustomAccordion-module--text--5d37c";