import React from "react";
import { Col } from "antd";

import * as classes from "./ProgramOverView.module.scss";

const OverviewCard = (props) => {
  const { overview } = props;
  return (
    <Col className={classes.overviewCard}>
      <Col>
        <Col>
          <img src={overview.icon} alt="sales certification programs" />
        </Col>
        <h3>{overview.title}</h3>
        <p>{overview.description}</p>
      </Col>
    </Col>
  );
};

export default OverviewCard;
