import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Typography } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import * as classes from "./CustomAccordion.module.scss";
import classNames from "classnames";

const { Paragraph } = Typography;

const CustomAccordion = (props) => {
  const {
    header,
    text,
    openIcon,
    closeIcon,
    containerClassName,
    iconClassName,
    textClassName,
    headerClassName,
    headingContainerClassName,
    activeKey,
    setActiveKey,
    currentKey,
  } = props;

  const open = useMemo(() => activeKey === currentKey);

  return (
    <Col className={classNames(classes.accordion, containerClassName)}>
      <Col
        className={classNames(
          classes.headingContainer,
          headingContainerClassName
        )}
      >
        <Col
          onClick={setActiveKey}
          className={classNames(classes.icon, iconClassName)}
        >
          {open ? closeIcon : openIcon}
        </Col>
        <Paragraph className={classNames(classes.header, headerClassName)}>
          {header}
        </Paragraph>
      </Col>
      {open && (
        <Paragraph className={classNames(classes.text, textClassName)}>
          {text}
        </Paragraph>
      )}
    </Col>
  );
};

CustomAccordion.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  openIcon: PropTypes.node,
  closeIcon: PropTypes.node,
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  textClassName: PropTypes.string,
  headingContainerClassName: PropTypes.string,
  activeKey: PropTypes.number,
  setActiveKey: PropTypes.func,
  currentKey: PropTypes.number,
};

CustomAccordion.defaultProps = {
  title: "Title",
  description: "description",
  openIcon: <PlusOutlined className={classes.plusIcon} />,
  closeIcon: <MinusOutlined className={classes.minusIcon} />,
};

export default CustomAccordion;
