import React, { useState } from "react";

import LayoutWithOutPadding from "../../../components/LayoutWithOutPadding";
import Hero from "../../../components/AdvanceSalesCertification1.0/Hero";
import CourseStats from "../../../components/AdvanceSalesCertification1.0/CourseStats";
import Reviews from "../../../components/AdvanceSalesCertification1.0/Reviews";
import Poweredby from "../../../components/AdvanceSalesCertification1.0/PoweredBy";
import ProgramOverView from "../../../components/AdvanceSalesCertification1.0/ProgramOverView";
import CurriculumDetails from "../../../components/AdvanceSalesCertification1.0/CurriculumDetails";
import Coaches from "../../../components/AdvanceSalesCertification1.0/Coaches";
import Tools from "../../../components/AdvanceSalesCertification1.0/Tools";
import HiringPartners from "../../../components/AdvanceSalesCertification1.0/HiringPartners";
import Advantages from "../../../components/AdvanceSalesCertification1.0/Advantages";
import Faq from "../../../components/AdvanceSalesCertification1.0/Faq";
import Seo from "../../../components/AdvanceSalesCertification1.0/Seo";
import seoTags from "../../../constants/salesPageSeoDetails";
import LeadSquareModal from "../../../components/AdvanceSalesCertification1.0/LeadSquareModal";
import AchivementsMobile from "../../../components/AdvanceSalesCertification1.0/AchivementsMobile";

const AdvanceSalesCertification = () => {
  const [leadSquareModalOpen, setLeadSquareModalOpen] = useState(false);

  const customStyles = {
    overlay: {
      background: "rgba(138, 145, 153, .66)",
      zIndex: "99",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
    },
  };

  return (
    <LayoutWithOutPadding>
      <Hero setLeadSquareModalOpen={setLeadSquareModalOpen} />
      <CourseStats />
      <AchivementsMobile />
      <Reviews />
      <Poweredby />
      <ProgramOverView />
      <CurriculumDetails setLeadSquareModalOpen={setLeadSquareModalOpen} />
      <Coaches />
      <Tools />
      <HiringPartners />
      <Advantages />
      <Faq />
      <LeadSquareModal
        open={leadSquareModalOpen}
        handleCancel={() => {
          setLeadSquareModalOpen(false);
        }}
        customStyles={customStyles}
      />
    </LayoutWithOutPadding>
  );
};

export function Head() {
  return (
    <Seo
      title={seoTags.metaTags.titleMetaTagContent}
      description={seoTags?.metaTags?.descriptionMetaTagContent}
      keywords={seoTags?.metaTags?.keywordsMetaTagContent}
      robots={seoTags?.metaTags?.robotsMetaTagContent}
      httpEquiv={seoTags?.metaTags?.httpEquivMetaTagContent}
      language={seoTags?.metaTags?.languageMetaTagContent}
      revisitAfter={seoTags?.metaTags?.revisitAfterMetaTagContent}
      author={seoTags?.metaTags?.authorMetaTagContent}
      schemaMarkup={seoTags?.scriptTags}
    />
  );
}

export default AdvanceSalesCertification;
