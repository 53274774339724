import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";

export const stats = [
  { value: "200k+", title: "Learners", id: 1 },
  { value: "500K+", title: "Enrollments", id: 2 },
  { value: "100%", title: "Placements", id: 3 },
  { value: "150+", title: "Industry experts", id: 4 },
  { value: "110+", title: "Hiring Partners", id: 5 },
];

export const reviews = [
  {
    id: 1,
    image: `${SALES_PAGE_FILES_PATH}/Bashavoni Jeevitha.jpg`,
    name: "Bashavoni Jeevitha",
    review:
      "'SALES JOB PROGRAM'. In this I had learnt about Sales,Maketing,B2B & B2C sales,and also about CRM. I gained very good knowledge from my mentors who had monitored in the live sessions. So,I think this course will turn up my Future with good knowledge.So that I can be in a good position where I can stand on my own feet. Unschool LeadSquared.",
    logo: `${SALES_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/bashavoni-jeevitha-3ba3b2254_hello-everyone-i-want-to-share-some-news-activity-7040681042902474752-PDS6/?utm_source=share&utm_medium=member_android",
  },
  {
    id: 2,
    image: `${SALES_PAGE_FILES_PATH}/Rachakonda Omprakash.jpg`,
    name: "Rachakonda Omprakash",
    review: `I just wanted to take a moment to share how impressed I am with the unschool+Leadsquared As a student, I have found the "sales job program" to be incredible engaging and informative and I feel like I have learned so much in a short amount of time. I would highly recommended them to anyone who looking to improve their skills and knowledge in a particular area. #sales #job #Unschool #leadsquared.`,
    logo: `${SALES_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/rachakonda-omprakash-5a569925b_sales-job-unschool-activity-7040394023588560896-RJgD/?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 3,
    image: `${SALES_PAGE_FILES_PATH}/avatar.png`,
    name: "Prasadi Kanaka Durgesh",
    review:
      "UNSCHOOL provides a great opportunity as a alternative educational platform.Their program's are much easier to understand and implement thorough which we can gain more knowledge and work experience as well to succeed in career as well as in the present society.",
    logo: `${SALES_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/prasadi-kanaka-durgesh-105881268_work-career-opportunity-activity-7037689637678501888-B4bs/?utm_source=share&utm_medium=member_android",
  },
  {
    id: 4,
    image: `${SALES_PAGE_FILES_PATH}/Shiva Kumar.jpg`,
    name: "Shiva Kumar",
    review:
      "SALES JOB PROGRAM' In which I can learn about sales, marketing, and also about the C.R.M app from lead squared. the experience I am getting is awesome and also gaining knowledge from the people who are engaging in our live sessions, I think this course can change my future in a positive way so that I can stand on my feet.",
    logo: `${SALES_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/shiva-kumar-06a012244_hello-guys-i-want-to-share-some-news-regarding-activity-7037418707463151616-OCzn/?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 5,
    image: `${SALES_PAGE_FILES_PATH}/avatar.png`,
    name: "Arva Hozefa Lokhandwala",
    review:
      "Unschool gave me opportunity for a Sales Job program, which turned out to be an amazing and educational journey. Unschool trained me and provided the necessary foundation for a sales job, and they were always available when we needed their assistance. I learned a lot about inside sales, how it works, and how important sales are to a company. So I am grateful to unschool for providing this opportunity, as well as to all of the unschool team members who guided and provided valuable insights throughout the process.",
    logo: `${SALES_PAGE_FILES_PATH}/google-reviews.png`,
    href: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURoaEwybnd3RRAB!2m1!1s0x0:0xde130fc10c45d3e!3m1!1s2@1:CIHM0ogKEICAgIDhhL2nwwE%7CCgwIxKP9nwYQsImIugI%7C?hl=en-US",
  },
  {
    id: 6,
    image: `${SALES_PAGE_FILES_PATH}/avatar.png`,
    name: "Prasadi Kanaka Durgesh",
    review: `My Personal Experience In SALES JOB PROGRAM:-
      During my time in a sales job program, I gained valuable experience in communication, negotiation, and persuasion. I learned how to identify and meet the needs of clients, and to work effectively in a team. The program provided me with opportunities to develop my skills through hands-on experience and mentorship. Overall, the sales job program helped me to build my confidence and prepared me for a successful career in sales.`,
    logo: `${SALES_PAGE_FILES_PATH}/google-reviews.png`,
    href: "https://www.google.com/maps/reviews/@17.4512166,78.3861049,17z/data=!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSURoeUstRzVBRRAB!2m1!1s0x0:0xde130fc10c45d3e?hl=en-US",
  },
];

export const brands = [
  {
    id: 1,
    image: `${SALES_PAGE_FILES_PATH}/leadSquare.svg`,
  },
  {
    id: 2,
    image: `${SALES_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
  },
  {
    id: 3,
    image: `${SALES_PAGE_FILES_PATH}/combinator.svg`,
  },
  {
    id: 4,
    image: `${SALES_PAGE_FILES_PATH}/near.svg`,
  },
  {
    id: 5,
    image: `${SALES_PAGE_FILES_PATH}/village-capital.svg`,
  },
  {
    id: 6,
    image: `${SALES_PAGE_FILES_PATH}/mailmado.svg`,
  },
  {
    id: 7,
    image: `${SALES_PAGE_FILES_PATH}/aws-ed-start.svg`,
  },
  {
    id: 8,
    image: `${SALES_PAGE_FILES_PATH}/lpi.svg`,
  },
  {
    id: 9,
    image: `${SALES_PAGE_FILES_PATH}/pwc.svg`,
  },
  {
    id: 12,
    image: `${SALES_PAGE_FILES_PATH}/global-shapers-logo.svg`,
  },
];

export const overviews = [
  {
    id: 1,
    icon: `${SALES_PAGE_FILES_PATH}/certificate.svg`,
    title: "Industry Certification",
    description:
      "Multiple certification opportunities from Unschool, Leadsquared, etc.",
  },
  {
    id: 2,
    icon: `${SALES_PAGE_FILES_PATH}/expert.svg`,
    title: "Worldclass Experts",
    description:
      "Access to Industry Experts and Teaching Assistants through the program",
  },
  {
    id: 3,
    icon: `${SALES_PAGE_FILES_PATH}/live-sessions.svg`,
    title: "Live Interactions",
    description:
      "Fortnightly immersive Guest lectures with Industry Leaders to Rekindle Your Mind",
  },
  {
    id: 4,
    icon: `${SALES_PAGE_FILES_PATH}/training.svg`,
    title: "Hands on Training",
    description: "Live Projects and Relevant Case Studies through the program",
  },
  {
    id: 5,
    icon: `${SALES_PAGE_FILES_PATH}/immerse-learning.svg`,
    title: "Immersive Learning",
    description:
      "Module Based Quizzes and Assessments to Test Your Knowledge at every stage",
  },
  {
    id: 6,
    icon: `${SALES_PAGE_FILES_PATH}/doubt-solving.svg`,
    title: "Doubt Solving",
    description:
      "Weekly Live Sessions of upto 3 hours with Live QnA every week for 60 minutes",
  },
  {
    id: 7,
    icon: `${SALES_PAGE_FILES_PATH}/video-content.svg`,
    title: "Video Content",
    description: "Self Paced Learning with over 15 hours of Video Content",
  },
  {
    id: 8,
    icon: `${SALES_PAGE_FILES_PATH}/job-assistance.svg`,
    title: "Job Assistance",
    description: "Mock interviews and Guidance in Resume Preparation",
  },
];

export const curriculum = [
  {
    id: 1,
    title: "Week 1: Introduction to Sales and Inside Sales",
    stats: "2 Live Sessions | 2 Quizzes",
    content: [
      { id: 1, title: "B2B Overview" },
      { id: 2, title: "Difference between B2B & B2C" },
      { id: 3, title: "Introduction to Inside Sales" },
      { id: 4, title: "Skills For Inside Sales" },
    ],
  },
  {
    id: 2,
    title: "Week 2: Understanding Marketing & Research",
    stats: "2 Live Sessions | 2 Quizzes | B2C Case Study | Minor Project",
    content: [
      { id: 1, title: "Introduction to Marketing" },
      { id: 2, title: "Strategic & Network Marketing" },
      { id: 3, title: "Digital Marketing" },
      { id: 4, title: "Market Research" },
      { id: 5, title: "Impact of Marketing " },
    ],
  },
  {
    id: 3,
    title: "Week 3: Lead journey and Selling techniques",
    stats: "2 Live Sessions | 2 Quizzes | B2B Case Study | Minor Project",
    content: [
      { id: 1, title: "Identifying Lead sources, Funnel & Stages" },
      { id: 2, title: "Customer Decision Journey" },
      { id: 3, title: "Negotiating & Closing Skills" },
      { id: 4, title: "Mastering Sales Technique" },
      { id: 5, title: "Managing pipeline & generating acqurate forcast" },
    ],
  },
  {
    id: 4,
    title: "Week 4: Leadsquared CRM",
    stats: "2 Live Sessions | 2 Quizzes | Final Assessment",
    content: [
      { id: 1, title: "Introduction to Leadsquared CRM" },
      { id: 2, title: "Exploring features in CRM" },
      { id: 3, title: "Working on Leadsquared CRM" },
      { id: 4, title: "Learn how CRM is used in various Industries" },
    ],
  },
  {
    id: 5,
    title: "Week 5: Getting Started with Various Career Paths in Inside Sales",
    stats:
      "2 Live Sessions | 1:1 Mock Interview | Resume & Linkedin profile building",
    content: [
      { id: 1, title: "How to become Inside Sales champaion" },
      { id: 2, title: "Career Paths in Sales" },
      { id: 3, title: "How to Crack interview" },
      { id: 4, title: "Bulding the right sales culture" },
    ],
  },
];

export const coaches = [
  {
    name: "Harjeet Singh Bedi",
    designation: "Product Trainer",
    imageUrl: `${SALES_PAGE_FILES_PATH}/Harjeet Singh Bedi.png`,
    companyLogo: `${SALES_PAGE_FILES_PATH}/leadSquare.svg`,
    id: 1,
  },
  {
    name: "Abhirami Lal",
    designation: "Product Trainer",
    imageUrl: `${SALES_PAGE_FILES_PATH}/Abhirami Lal.png`,
    companyLogo: `${SALES_PAGE_FILES_PATH}/leadSquare.svg`,
    id: 2,
  },
  {
    name: "Kushal Bharti",
    designation: "Product Trainer",
    imageUrl: `${SALES_PAGE_FILES_PATH}/Kushal Bharti.png`,
    companyLogo: `${SALES_PAGE_FILES_PATH}/leadSquare.svg`,
    id: 3,
  },
  {
    name: "Rahul Varma",
    designation: "Chief Executive Officer",
    imageUrl: `${SALES_PAGE_FILES_PATH}/Rahul.png`,
    companyLogo: `${SALES_PAGE_FILES_PATH}/unschool.png`,
    id: 4,
  },
  {
    name: "Narayanan S",
    designation: "Chief Business Officer",
    imageUrl: `${SALES_PAGE_FILES_PATH}/Narayan.png`,
    companyLogo: `${SALES_PAGE_FILES_PATH}/unschool.png`,
    id: 5,
  },
];

export const hiringCompanies = [
  {
    id: 1,
    image: `${SALES_PAGE_FILES_PATH}/accenture.png`,
  },
  {
    id: 2,
    image: `${SALES_PAGE_FILES_PATH}/amazon.png`,
  },
  {
    id: 3,
    image: `${SALES_PAGE_FILES_PATH}/siemens.png`,
  },
  {
    id: 4,
    image: `${SALES_PAGE_FILES_PATH}/xiomi.png`,
  },
  {
    id: 5,
    image: `${SALES_PAGE_FILES_PATH}/hotstar.png`,
  },
  {
    id: 6,
    image: `${SALES_PAGE_FILES_PATH}/medianet.png`,
  },
  {
    id: 7,
    image: `${SALES_PAGE_FILES_PATH}/slx.png`,
  },
  {
    id: 8,
    image: `${SALES_PAGE_FILES_PATH}/freshworks.png`,
  },
  {
    id: 9,
    image: `${SALES_PAGE_FILES_PATH}/delta-x.png`,
  },
];

export const advantages = [
  {
    id: 1,
    title: "Case Studies & Projects",
    image: `${SALES_PAGE_FILES_PATH}/advantage-icon-1.png`,
    content: [
      { id: 1, title: "Solve real-life industry problems" },
      {
        id: 2,
        title:
          "Brush-up your sales skills by working on the latest industry case studies",
      },
    ],
  },
  {
    id: 2,
    title: "Teaching Assistance",
    image: `${SALES_PAGE_FILES_PATH}/advantage-icon-2.png`,
    content: [
      {
        id: 1,
        title: "Get round the clock mentor support to get your doubts resolved",
      },
      {
        id: 2,
        title:
          "Receive one-on-one feedback on submissions and personalized feedback on improvement",
      },
    ],
  },
  {
    id: 3,
    title: "Industry relevant content",
    image: `${SALES_PAGE_FILES_PATH}/advantage-icon-3.png`,
    content: [
      {
        id: 1,
        title:
          "Learn best-in-class content by leading faculty and industry leaders in the form of videos, case studies, projects, assignments and live sessions",
      },
      {
        id: 2,
        title: "Get hands-on experience with trending sales tools",
      },
    ],
  },
];

export const toolsFeaturesOne = [
  {
    id: 1,
    title: "Prospecting",
  },
  {
    id: 4,
    title: "Lead Generation",
  },
  {
    id: 7,
    title: "Appointment closing",
  },
  {
    id: 2,
    title: "Referrals",
  },
  {
    id: 5,
    title: "Sales scripting",
  },
];

export const toolsFeaturesTwo = [
  {
    id: 8,
    title: "Trust and rapport",
  },
  {
    id: 3,
    title: "Objection Handling",
  },
  {
    id: 6,
    title: "Goal setting",
  },
  {
    id: 9,
    title: "Deal closing",
  },
];

export const faqs = [
  {
    question: "Who can benefit from a sales job program? ",
    answer:
      "Anyone interested in pursuing a career in sales or looking to enhance their existing sales skills can benefit from a sales job program. It can benefit new sales representatives or individuals transitioning to a sales role from another domain.",
  },
  {
    question: "What are the components of a sales job program? ",
    answer:
      "A sales job program includes online sessions, case studies, and on-the-job training. It also incorporates mentoring and coaching by experienced sales professionals from Unschool and Leadsquared.",
  },
  {
    question: "Are there any prerequisites for attending a sales job program? ",
    answer:
      "One prerequisite for attending a sales job program is a minimum level of education or previous experience. However, our programs are open to individuals without sales experience.",
  },
  {
    question:
      "Are there any certifications or credentials associated with completing a sales job program?",
    answer:
      "Yes, our sales job programs will offer certifications or credentials upon completion. These certifications can enhance your credibility and demonstrate your proficiency to potential employers.",
  },
  {
    question: "What skills can I expect to learn in a sales job program? ",
    answer:
      "A sales job program covers many skills, including communication skills, relationship building, customer service, time management, and sales techniques such as objection handling and closing.",
  },
];
