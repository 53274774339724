// extracted by mini-css-extract-plugin
export var bottom = "CourseStats-module--bottom--c245e";
export var cardFive = "CourseStats-module--cardFive--9077f";
export var cardFour = "CourseStats-module--cardFour--bf1b7";
export var cardOne = "CourseStats-module--cardOne--930a7";
export var cardSix = "CourseStats-module--cardSix--55d96";
export var cardThree = "CourseStats-module--cardThree--1d3f8";
export var cardTwo = "CourseStats-module--cardTwo--914ac";
export var container = "CourseStats-module--container--d185d";
export var left = "CourseStats-module--left--99a00";
export var lmsScrollbar = "CourseStats-module--lms-scrollbar--5cb9b";
export var right = "CourseStats-module--right--aecbb";
export var rightDesktop = "CourseStats-module--rightDesktop--2969e";
export var stats = "CourseStats-module--stats--aa10a";
export var statsContainer = "CourseStats-module--statsContainer--b65b9";
export var top = "CourseStats-module--top--d0984";
export var wrapper = "CourseStats-module--wrapper--0df80";