import React from "react";
import { Col } from "antd";

import * as classes from "./Tools.module.scss";
import { toolsFeaturesOne, toolsFeaturesTwo } from "../Utils/data";
import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";

const Tools = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.left}>
          <Col className={classes.heading}>
            <h1>Learn all the industry relevant tools</h1>
            <p>
              Get acquainted to 20+ top sales tools to become high performing
              Sales Strategists in the digital world.
            </p>
          </Col>
        </Col>
        <Col className={classes.right}>
          <Col className={classes.toolFeatures}>
            <Col>
              {toolsFeaturesOne.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`}
                      alt="online sales course job program"
                    />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
            <Col>
              {toolsFeaturesTwo.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`}
                      alt="sales training with job"
                    />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Tools;
