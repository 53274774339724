import React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";

import * as classes from "./ReactSlickController.module.scss";
import classNames from "classnames";

export const ReactSlickController = (props) => {
  const {
    next,
    prev,
    activeSlide,
    totalSlides,
    slickGoTo,
    slidesToShow,
    buttonClassName,
    buttonStyle,
    buttonArrowStyle,
    activeProgressItemClassName,
    progressItemClassName,
  } = props;

  const totalSlidesArray = [...Array(totalSlides).keys()];

  return (
    <Col
      className={classNames(
        classes.container,
        totalSlides <= slidesToShow && classes.hidden
      )}
    >
      <Col className={classes.wrapper}>
        <Col className={classes.buttonsContainer}>
          <button
            onClick={prev}
            className={classNames(
              buttonClassName,
              activeSlide === 0 && classes.disabled
            )}
            style={buttonStyle}
          >
            <span
              className={classNames(activeSlide === 0 && classes.disabled)}
              style={buttonArrowStyle}
            >
              &lt;
            </span>
          </button>
          <button
            onClick={next}
            className={classNames(
              buttonClassName,
              activeSlide === totalSlides - 1 && classes.disabled
            )}
            style={buttonStyle}
          >
            <span
              className={classNames(
                activeSlide === totalSlides - 1 && classes.disabled
              )}
              style={buttonArrowStyle}
            >
              &gt;
            </span>
          </button>
        </Col>
        <Col className={classes.sliderProgress}>
          <Col
            className={classNames(
              classes.activeProgressItem,
              activeProgressItemClassName
            )}
            style={{
              left: `${activeSlide * 35}px`,
            }}
          />
          {totalSlidesArray.map((item, index) => {
            return (
              <div
                className={classNames(
                  classes.progressItem,
                  progressItemClassName
                )}
                key={item}
                onClick={() => {
                  slickGoTo(index);
                }}
              />
            );
          })}
        </Col>
      </Col>
    </Col>
  );
};

ReactSlickController.propTypes = {
  buttonStyle: PropTypes.object,
  buttonArrowStyle: PropTypes.object,
};

ReactSlickController.defaultProps = {
  buttonStyle: {},
  buttonArrowStyle: {},
};
