import { Col } from "antd";
import React from "react";
import Modal from "react-modal";
import { CloseCircleOutlined } from "@ant-design/icons";

import * as classes from "./LeadSquareModal.module.scss";

const LeadSquareModal = (props) => {
  const { open, handleCancel, customStyles } = props;

  const screenHeight = window.innerHeight;

  return (
    <Modal isOpen={open} onRequestClose={handleCancel} style={customStyles}>
      <Col className={classes.container}>
        <h3>Advanced Program in Strategic Sales Management</h3>
        <CloseCircleOutlined
          className={classes.closeIcon}
          onClick={handleCancel}
        />
        <Col
          className={classes.leadsquareFormContainer}
          style={{
            height: screenHeight < 500 ? `400px` : "400px",
          }}
        >
          <iframe
            name="leadsquared_landing_page_frame"
            src="https://edupolis.viewpage.co/SalesDevelopment_JobsProgram_BrochureForm"
            frameborder="1"
            className={classes.leadsquareForm}
          />
        </Col>
      </Col>
    </Modal>
  );
};

export default LeadSquareModal;
