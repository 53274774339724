import { Col } from "antd";
import React from "react";

import * as classes from "./ProgramOverView.module.scss";
import OverviewCard from "./OverviewCard";
import { overviews } from "../Utils/data";

const ProgramOverView = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>
          Program <span>Overview</span>
        </h1>
        <Col className={classes.overviews}>
          {overviews.map((overview) => {
            return <OverviewCard overview={overview} key={overview.id} />;
          })}
        </Col>
      </Col>
    </Col>
  );
};

export default ProgramOverView;
