export default {
  metaTags: {
    titleMetaTagContent:
      "Advance Sales Certification - 100% Guaranteed Job Program | Unschool",
    descriptionMetaTagContent:
      "This Advance sales certification course with guaranteed job program by Leadsquared equips you to become a sales professional. Enroll now for next cohort.",
    keywordsMetaTagContent:
      "sales certification, sales certification course, certified sales professional, sales certification programs, sales certification online, certificate in sales and marketing, online certification courses in sales, sales training certification, best sales certifications, best sales certifications 2023, sales training programs near me, best certifications for sales professionals, leadsquared sales job program, sales certification job program, sales training with guaranteed job , sales course with guaranteed job, sales online course with job program, online sales course job program, leadsquared job program for sales, job program for sales, sales guaranteed job program, sales certification online course, sales training with job, Sales certification job program in Hyderabad",
    robotsMetaTagContent: "index, follow",
    httpEquivMetaTagContent: "text/html; charset=utf-8",
    languageMetaTagContent: "English",
    revisitAfterMetaTagContent: "1 days",
    authorMetaTagContent: "Unschool",
  },
  scriptTags: [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "Advance Sales Certification Job Program",
      Image:
        "https://www.unschool.in/static/Unschool%20Logo%20Horizontal%20White%20(1)-a4913f0c391a347aa53acfa994243cb4.png",
      description:
        "Start your Sales Certification with 100% Job guarantee program by Leadsquared powered by Unschool today.",
      brand: "Unschool",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "4",
        ratingCount: "2037",
      },
      sku: "Job Program",
      mpn: "UA-134257618-1",
    },
    {
      "@context": "http://schema.org",
      "@type": "VideoObject",
      name: '"Launch Your Sales Career with Confidence: Enrol in Our Sales Certificate Job Program Today!"',
      description:
        '"Ready to kickstart your sales career? Our Sales Certificate Job Program provides comprehensive training on sales strategies, lead generation, and customer behaviour. Gain practical experience and earn a Sales Certificate to showcase your skills to potential employers. Enrol now and take the first step towards success in sales!" #sales #unschool #trending #marketing #salesjobs',
      thumbnailUrl: "https://i.ytimg.com/vi/6WxvXZYkxdg/default.jpg",
      uploadDate: "2023-03-28T09:54:36Z",
      duration: "PT1M44S",
      embedUrl: "https://www.youtube.com/embed/6WxvXZYkxdg",
      interactionCount: "3",
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Who can benefit from a sales job program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Anyone interested in pursuing a career in sales or looking to enhance their existing sales skills can benefit from a sales job program. It can benefit new sales representatives or individuals transitioning to a sales role from another domain.",
          },
        },
        {
          "@type": "Question",
          name: "What are the components of a sales job program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A sales job program includes online sessions, case studies, and on-the-job training. It also incorporates mentoring and coaching by experienced sales professionals from Unschool and Leadsquared.",
          },
        },
        {
          "@type": "Question",
          name: "Are there any prerequisites for attending a sales job program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "One prerequisite for attending a sales job program is a minimum level of education or previous experience. However, our programs are open to individuals without sales experience.",
          },
        },
        {
          "@type": "Question",
          name: "Are there any certifications or credentials associated with completing a sales job program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, our sales job programs will offer certifications or credentials upon completion. These certifications can enhance your credibility and demonstrate your proficiency to potential employers.",
          },
        },
        {
          "@type": "Question",
          name: "What skills can I expect to learn in a sales job program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A sales job program covers many skills, including communication skills, relationship building, customer service, time management, and sales techniques such as objection handling and closing.",
          },
        },
      ],
    },
  ],
  imageAltAttributes: [
    "sales certification programs",
    "sales certification online",
    "best sales certifications 2023",
    "leadsquared sales job program",
    "sales course with guaranteed job",
    "sales training with guaranteed job",
    "best certifications for sales professionals",
    "online sales course job program",
    "sales training with job",
    "Sales certification job program in Hyderabad",
  ],
};
