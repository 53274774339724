// extracted by mini-css-extract-plugin
export var bottom = "CurriculumDetails-module--bottom--2e9ce";
export var buttonCustomClass = "CurriculumDetails-module--buttonCustomClass--a4c64";
export var buttonCustomTextClass = "CurriculumDetails-module--buttonCustomTextClass--d2b34";
export var container = "CurriculumDetails-module--container--4b44a";
export var curicullum = "CurriculumDetails-module--curicullum--ef82b";
export var curicullumItem = "CurriculumDetails-module--curicullumItem--6773b";
export var left = "CurriculumDetails-module--left--5add7";
export var lmsScrollbar = "CurriculumDetails-module--lms-scrollbar--81137";
export var minusToggleIcon = "CurriculumDetails-module--minusToggleIcon--df65f";
export var plusToggleIcon = "CurriculumDetails-module--plusToggleIcon--893f5";
export var projectsTop = "CurriculumDetails-module--projectsTop--371f6";
export var projectsTopBottom = "CurriculumDetails-module--projectsTopBottom--1b9fc";
export var right = "CurriculumDetails-module--right--dae20";
export var stats = "CurriculumDetails-module--stats--e0c5d";
export var toggleIcon = "CurriculumDetails-module--toggleIcon--67a9a";
export var top = "CurriculumDetails-module--top--0bd73";
export var wrapper = "CurriculumDetails-module--wrapper--bff9a";