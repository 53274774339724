import { Col } from "antd";
import React, { useEffect } from "react";

import * as classes from "./Reviews.module.scss";

const ReviewCard = (props) => {
  const { review } = props;

  useEffect(() => {
    setTimeout(() => {
      const caseStudyItems = document.getElementsByClassName(
        classes.reviewCard
      );
      let maxEleHeight = 0;
      for (let i = 0; i < caseStudyItems.length; i++) {
        if (caseStudyItems[i].offsetHeight > maxEleHeight) {
          maxEleHeight = caseStudyItems[i].offsetHeight;
        }
      }
      for (let i = 0; i < caseStudyItems.length; i++) {
        caseStudyItems[i].style.height = `${maxEleHeight}px`;
      }
    }, 1000);
  }, []);

  return (
    <Col className={classes.reviewCard}>
      <Col>
        <img src={review.image} alt="online sales course job program" />
      </Col>
      <Col>
        <h3>{review.name}</h3>
        <p>{review.review}</p>
      </Col>
      <Col>
        <a href={review.href} target="_blank">
          <img src={review.logo} alt="sales training with job" />
        </a>
      </Col>
    </Col>
  );
};

export default ReviewCard;
