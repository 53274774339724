import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Typography } from "antd";
import Slider from "react-slick";

import * as classes from "./Coaches.module.scss";
import CoachCard from "./CoachCard";
import { coaches } from "../Utils/data";
import { ReactSlickController } from "../../adaptors/ReactSlickController";
import { isSSR } from "../../../helpers/global";

const { Title } = Typography;

const Coaches = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const calculateNoOfSildesToShow = useMemo(() => {
    if (isSSR()) {
      return 0;
    } else {
      const width = window?.innerWidth;
      if (width <= 725) {
        return 1;
      }
      if (width <= 1000) {
        return 2;
      }
      if (width <= 1300) {
        return 3;
      }
      if (width <= 1600) {
        return 4;
      }
      return 5;
    }
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 5,
    speed: 500,
    rows: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          dots: false,
          arrows: false,
        },
      },
      {
        dots: false,
        arrows: false,
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: false,
        },
      },
      {
        dots: false,
        arrows: false,
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        dots: false,
        arrows: false,
        breakpoint: 725,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Title>
        Learn Directly <span>From Experts</span>
      </Title>
      <p>
        Our Industry experts come from renowned industries and with years of
        experience.
      </p>
      <Col className={classes.coachCarousel}>
        <Slider ref={ref} {...settings}>
          {coaches.map((coach) => (
            <CoachCard key={coach.id} coach={coach} />
          ))}
        </Slider>
        <Col className={classes.controlButtons}>
          <ReactSlickController
            next={buttonsController.next}
            prev={buttonsController.prev}
            slickGoTo={buttonsController.slickGoTo}
            activeSlide={activeSlide}
            totalSlides={coaches.length}
            slidesToShow={calculateNoOfSildesToShow}
          />
        </Col>
      </Col>
    </Col>
  );
};

export default Coaches;
