// extracted by mini-css-extract-plugin
export var buttonCustomClass = "Hero-module--buttonCustomClass--db359";
export var buttonCustomTextClass = "Hero-module--buttonCustomTextClass--29a1a";
export var courseDescription = "Hero-module--courseDescription--1b96b";
export var courseHeroContainer = "Hero-module--courseHeroContainer--d0e82";
export var courseHeroContentLeft = "Hero-module--courseHeroContentLeft--10d2c";
export var courseHeroContentRight = "Hero-module--courseHeroContentRight--757ba";
export var courseHeroWrapper = "Hero-module--courseHeroWrapper--2422e";
export var courseRating = "Hero-module--courseRating--af946";
export var courseStats = "Hero-module--courseStats--62398";
export var courseStatsItem = "Hero-module--courseStatsItem--04128";
export var coursesDetails = "Hero-module--coursesDetails--a922b";
export var heroTitle = "Hero-module--heroTitle--fd766";
export var horizontalRuler = "Hero-module--horizontalRuler--2c38f";
export var lmsScrollbar = "Hero-module--lms-scrollbar--b04ae";
export var videoContainer = "Hero-module--videoContainer--b093b";
export var videoIframe = "Hero-module--videoIframe--feb92";