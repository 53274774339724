// extracted by mini-css-extract-plugin
export var achievements = "AchivementsMobile-module--achievements--0d33e";
export var achievementsContent = "AchivementsMobile-module--achievementsContent--50334";
export var cardFiveMobile = "AchivementsMobile-module--cardFiveMobile--5f475";
export var cardFourMobile = "AchivementsMobile-module--cardFourMobile--7a2e3";
export var cardOneMobile = "AchivementsMobile-module--cardOneMobile--8f38d";
export var cardSixMobile = "AchivementsMobile-module--cardSixMobile--b0d9d";
export var cardThreeMobile = "AchivementsMobile-module--cardThreeMobile--168e1";
export var cardTwoMobile = "AchivementsMobile-module--cardTwoMobile--ca42b";
export var container = "AchivementsMobile-module--container--6d5b0";
export var lmsScrollbar = "AchivementsMobile-module--lms-scrollbar--f84f7";
export var stats = "AchivementsMobile-module--stats--35dd5";
export var statsContainer = "AchivementsMobile-module--statsContainer--a7bc7";