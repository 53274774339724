// extracted by mini-css-extract-plugin
export var accordianHeader = "Faq-module--accordianHeader--b2e1e";
export var accordianItem = "Faq-module--accordianItem--29a03";
export var accordianText = "Faq-module--accordianText--8a846";
export var contactUnschool = "Faq-module--contactUnschool--def2a";
export var courseFaqContainer = "Faq-module--courseFaqContainer--9bf07";
export var courseFaqContent = "Faq-module--courseFaqContent--508c3";
export var courseFaqContentLeft = "Faq-module--courseFaqContentLeft--ed598";
export var courseFaqContentRight = "Faq-module--courseFaqContentRight--a1494";
export var faqImageContainer = "Faq-module--faqImageContainer--77874";
export var headingContainer = "Faq-module--headingContainer--c6e85";
export var headingDesktop = "Faq-module--headingDesktop--8c95a";
export var headingMobile = "Faq-module--headingMobile--27cae";
export var iconClassName = "Faq-module--iconClassName--3947a";
export var lmsScrollbar = "Faq-module--lms-scrollbar--de86d";