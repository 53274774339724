import { Col } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import * as classes from "./Reviews.module.scss";
import ReviewCard from "./ReviewCard";
import { reviews } from "../Utils/data";
import { ReactSlickController } from "../../adaptors/ReactSlickController";

const Reviews = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>
          Hear from our <span>learners</span>
        </h1>
        <Col className={classes.reviewsCarousel}>
          <Slider ref={ref} {...settings}>
            {reviews.map((review) => (
              <ReviewCard review={review} key={review.id} />
            ))}
          </Slider>
          <Col className={classes.controlButtons}>
            <ReactSlickController
              next={buttonsController.next}
              prev={buttonsController.prev}
              slickGoTo={buttonsController.slickGoTo}
              activeSlide={activeSlide}
              totalSlides={reviews.length}
              slidesToShow={1}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Reviews;
