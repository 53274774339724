import React, { useEffect } from "react";
import PropTypes from "prop-types";
import GrayStarIcon from "../img/images/icons/gray-star.png";
import GoldStarIcon from "../img/images/icons/gold-star.png";

export const StarRatings = ({
  rating,
  reviewers,
  starClass,
  spacing,
  desktopPercentOffset,
}) => {
  const __percentage = rating / 5;

  // const __percentage = (rating / 5) * 100 + (desktopPercentOffset || 0);

  const [percentage, setPercentage] = React.useState(__percentage);

  const containerRef = React.useRef(null);
  const starRef = React.useRef(null);

  const calculateWidth = () => {
    const stars = rating;
    const maxStars = 5;
    const ratingPercent = stars / maxStars;
    const starSize = starRef.current.clientWidth;
    const marginWidth = (spacing || 0) * 4;
    const coveredWidth = starSize * 5 + marginWidth;
    setPercentage(coveredWidth * ratingPercent + 1);
  };

  useEffect(() => {
    if (containerRef.current && starRef.current) {
      calculateWidth();
      // const fullWidth = containerRef.current.clientWidth;
      // setPercentage(fullWidth * __percentage);
    }
  });

  return (
    <div id="course-page-rating">
      <div className="stars-container-div" ref={containerRef}>
        <div className={"gray-stars-container"}>
          {[1, 2, 3, 4, 5].map((val, index) => {
            return (
              <React.Fragment key={index}>
                <img
                  className={starClass || "course-page-rating-icon"}
                  src={GrayStarIcon}
                  alt="Rating"
                  ref={val === 1 ? starRef : null}
                />
                {val !== 5 && (
                  <div style={{ width: spacing, flex: "0 0 auto" }} />
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div
          className="gold-stars-container"
          style={{ width: `${percentage}px` }}
        >
          {[1, 2, 3, 4, 5].map((val, index) => {
            return (
              <React.Fragment key={index}>
                <img
                  className={starClass || "course-page-rating-icon"}
                  src={GoldStarIcon}
                  alt="Rating"
                  key={index}
                />
                {val !== 5 && (
                  <div style={{ width: spacing, flex: "0 0 auto" }} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {!Number.isNaN(reviewers) && !Number.isNaN(rating) && (
        <h6>
          {rating} ({reviewers} ratings)
        </h6>
      )}
    </div>
  );
};

StarRatings.propTypes = {
  rating: PropTypes.number,
  reviewers: PropTypes.number,
  starClass: PropTypes.string,
  desktopPercentOffset: PropTypes.number,
  mobilePercentOffset: PropTypes.number,
  spacing: PropTypes.number,
};

export default StarRatings;
