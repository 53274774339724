import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";
import Slider from "react-slick";

import * as classes from "./Advantages.module.scss";
import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";
import { advantages } from "../Utils/data";
import { ReactSlickController } from "../../adaptors/ReactSlickController";

const Advantages = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>
          <span>The Unschool</span> Advantage
        </h1>
        <Col className={classes.advantagesDesktop}>
          {advantages.map((advantage) => (
            <Col className={classes.advantageItem} key={advantage.id}>
              <h2>{advantage.title}</h2>
              {advantage.content.map((item) => (
                <Col key={item.id}>
                  <img
                    src={`${SALES_PAGE_FILES_PATH}/yellow-tick-icon-2.svg`}
                    alt="sales certification programs"
                  />
                  <p>{item.title}</p>
                </Col>
              ))}
              <img src={advantage.image} alt="sales certification online" />
            </Col>
          ))}
        </Col>
        <Col className={classes.advantagesMobile}>
          <Slider ref={ref} {...settings}>
            {advantages.map((advantage) => (
              <Col className={classes.advantageItem} key={advantage.id}>
                <h2>{advantage.title}</h2>
                {advantage.content.map((item) => (
                  <Col key={item.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/yellow-tick-icon-2.svg`}
                      alt="best sales certifications 2023"
                    />
                    <p>{item.title}</p>
                  </Col>
                ))}
                <img
                  src={advantage.image}
                  alt="leadsquared sales job program"
                />
              </Col>
            ))}
          </Slider>
          <Col className={classes.controlButtons}>
            <ReactSlickController
              next={buttonsController.next}
              prev={buttonsController.prev}
              slickGoTo={buttonsController.slickGoTo}
              activeSlide={activeSlide}
              totalSlides={advantages.length}
              slidesToShow={1}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Advantages;
