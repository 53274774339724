import { Col, Typography } from "antd";
import React from "react";

import * as classes from "./AchivementsMobile.module.scss";
import { stats } from "../Utils/data";
import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

const { Title } = Typography;

const AchivementsMobile = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.achievements}>
        <Marquee>
          <Col className={classes.achievementsContent}>
            <Col className={classes.cardOneMobile}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/bussiness-world.png`}
                alt="leadsquared sales job program"
              />
              <p>Hyderabad Youths Create Start-Up- 'Unschool, To Bridge Gap</p>
            </Col>
            <Col className={classes.cardTwoMobile}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/linkedIn.svg`}
                alt="sales course with guaranteed job"
              />
              <p>
                Unschool is ranked 3rd on LinkedIn 2020 top startup list in
                India.
              </p>
            </Col>
            <Col className={classes.cardThreeMobile}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/print.svg`}
                alt="leadsquared job program for sales"
              />
              <p>Preparing India for the future workplace.</p>
            </Col>
            <Col className={classes.cardFourMobile}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/combinator.svg`}
                alt="sales training with guaranteed job"
              />
              <p>Y Combinator widens its bet in edtech in latest batch.</p>
            </Col>
            <Col className={classes.cardFiveMobile}>
              <h3>
                <span>Unschool</span> In
              </h3>
              <h3>The News</h3>
              <Col />
            </Col>
            <Col className={classes.cardSixMobile}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/your-story.svg`}
                alt="best certifications for sales professionals"
              />
              <p>
                This Hyderabad-based edtech startup aims to help students
                ‘un’school themselves.
              </p>
            </Col>
          </Col>
        </Marquee>
      </Col>
      <Col className={classes.statsContainer}>
        <Col className={classes.stats}>
          {stats.map((stat) => {
            return (
              <Col key={stat.id}>
                <Title>{stat.value}</Title>
                <p>{stat.title}</p>
              </Col>
            );
          })}
        </Col>
      </Col>
    </Col>
  );
};

export default AchivementsMobile;
