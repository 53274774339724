import React, { useState } from "react";
import { Col, Modal, Typography } from "antd";

import * as classes from "./Hero.module.scss";
import StarRatings from "../../StarRatings";
import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";

const { Title, Paragraph } = Typography;

const Hero = (props) => {
  const { setLeadSquareModalOpen } = props;

  return (
    <Col className={classes.courseHeroContainer}>
      <Col className={classes.courseHeroWrapper}>
        <Col className={classes.courseHeroContentLeft}>
          <Title className={classes.heroTitle}>
            Advance Sales Certification Job Program
          </Title>
          <Col className={classes.courseRating}>
            <StarRatings rating={4.1} reviewers={"100+"} spacing={4} />
          </Col>
          <Paragraph className={classes.courseDescription}>
            Unschool & Leadsquared bring you the Executive Program in Strategic
            Sales Management to aid aspiring as well as existing Sales
            Professionals to become a high-performing and dynamic salesforce.
            With the right training, you can transform into a digitally sound
            Sales Strategist who can meet the organisation's needs and be
            future-ready in the sales domain! It is time to learn from the
            Leaders to become One!
          </Paragraph>
          <Col className={classes.courseStats}>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/learning.svg`}
                alt="sales certification programs"
              />
              <span>40+hrs Applied Learning</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/hybrid.svg`}
                alt="sales certification online"
              />
              <span>Hybrid Mode</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/group.svg`}
                alt="best sales certifications 2023"
              />
              <span>Pay after Placement</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/certification.svg`}
                alt="leadsquared sales job program"
              />
              <span>CRM certification</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${SALES_PAGE_FILES_PATH}/job.svg`}
                alt="sales course with guaranteed job"
              />
              <span>Job Guarantee</span>
            </Col>
          </Col>
          <CustomButton
            title={"Apply Now"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              setLeadSquareModalOpen(true);
            }}
          />
        </Col>
        <Col className={classes.courseHeroContentRight}>
          <Col>
            <Col className={classes.videoContainer}>
              <iframe
                className={classes.videoIframe}
                src="https://player.vimeo.com/video/795800528?h=e311b4a50a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                title="Advanced Sales Certificaion"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen={true}
                id="iframe"
              />
              <script src="https://player.vimeo.com/api/player.js"></script>
            </Col>
          </Col>
        </Col>
        <Col className={classes.coursesDetails}>
          <Col>
            <h3>Next Cohort Starts 20th April / 15th May, 2023</h3>
            <p>Limited seats only available</p>
          </Col>
          <Col className={classes.horizontalRuler}></Col>
          <hr />
          <Col>
            <h3>Program Duration: 4 Weeks</h3>
            <p>Approx. 8-10 hours/week</p>
          </Col>
          <Col className={classes.horizontalRuler}></Col>
          <hr />

          <Col>
            <h3>Job Guarantee after Completion</h3>
            <p>1 Week of Career Assistance</p>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Hero;
