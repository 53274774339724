import { Col } from "antd";
import React from "react";

import * as classes from "./CurriculumDetails.module.scss";
import ModuleCard from "./ModuleCard";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";
import { curriculum } from "../Utils/data";

const CurriculumDetails = (props) => {
  const { setLeadSquareModalOpen } = props;

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.top}>
          <Col>
            <h1>Best in industry curriculum</h1>
            <p>
              Best-in-class content by leading faculty and industry leaders in
              the form of videos, live sessions and projects
            </p>
          </Col>
          <Col className={classes.stats}>
            <Col>
              <img
                src={`${SALES_PAGE_FILES_PATH}/training-1.svg`}
                alt="sales certification online"
              />
              <h3>4 Weeks of Extensive Training</h3>
            </Col>
            <Col>
              <img
                src={`${SALES_PAGE_FILES_PATH}/projects.svg`}
                alt="best sales certifications 2023"
              />
              <h3>Case Studies and Projects</h3>
            </Col>
            <Col>
              <img src={`${SALES_PAGE_FILES_PATH}/tools.svg`} />
              <h3>10+ tools and skills</h3>
            </Col>
            <Col>
              <img
                src={`${SALES_PAGE_FILES_PATH}/live-sessions.svg`}
                alt="leadsquared sales job program"
              />
              <h3>Over 15 Live Interactions</h3>
            </Col>
          </Col>
          <Col className={classes.curicullum}>
            <Col className={classes.left}>
              {curriculum.map((module) => {
                return <ModuleCard module={module} key={module.id} />;
              })}
            </Col>
            <Col className={classes.right}>
              <img src={`${SALES_PAGE_FILES_PATH}/brochure.png`} />
              <CustomButton
                title={"Download Course Curriculum"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
                onClick={() => {
                  setLeadSquareModalOpen(true);
                }}
              />
            </Col>
          </Col>
        </Col>
        <Col className={classes.bottom}>
          <Col className={classes.projectsTop}>
            <h1>Industry Projects and Case Studies</h1>
          </Col>
          <Col className={classes.projectsTopBottom}>
            <Col>
              <img
                src={`${SALES_PAGE_FILES_PATH}/case-study-1.png`}
                alt="sales course with guaranteed job"
              />
              <div>
                <h3>B2C Case Study</h3>
                <h3>UCP Model</h3>
              </div>
            </Col>
            <Col>
              <img
                src={`${SALES_PAGE_FILES_PATH}/case-study-2.png`}
                alt="leadsquared job program for sales"
              />
              <div>
                <h3>B2B Case Study</h3>
                <h3>Inside Sales Strategy</h3>
              </div>
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default CurriculumDetails;
