import { Col } from "antd";
import React from "react";

import * as classes from "./HiringPartners.module.scss";
import { hiringCompanies } from "../Utils/data";

const HiringPartners = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.left}>
          <Col className={classes.company}>
            {hiringCompanies.map((company) => {
              return (
                <Col key={company.id}>
                  <img
                    src={company.image}
                    alt="Sales certification job program in Hyderabad"
                  />
                </Col>
              );
            })}
          </Col>
        </Col>
        <Col className={classes.right}>
          <h1>Our Global Hiring Partners</h1>
          <p>
            Get a 100% Guaranteed Job Placement Assistance and work with top
            digital marketing agencies and brands across the globe.
          </p>
        </Col>
      </Col>
    </Col>
  );
};

export default HiringPartners;
